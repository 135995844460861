/* General styles */
* {
  box-sizing: border-box;
}

.App {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.black-background {
  background-color: black;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;
}

.image-text-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 50px 0;
  background-color: #8a0000;
  object-fit: cover;
}

.image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  padding: 5px;
  padding-top: 25px;
  background-color: white;
  border-radius: 1px;
  margin-right: 10px;
  margin-left: 10%;
  flex-grow: 2;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: rgb(255, 255, 255);
  font-size: 1.5vw;
  text-align: left;
  z-index: 1;
  padding: 20px;
  padding-left: 2%;
  background: rgba(228, 0, 0, 0.548);
  border-radius: 1px;
  width: 50%;
  margin-left: 5%;
  margin-right: 5%;
  flex-grow: 1;
}

.image-column img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 10%;
  border-radius: 5px;
  object-fit: contain;
}

@keyframes blurOut {
  0% { filter: blur(0); opacity: 1; }
  100% { filter: blur(10px); opacity: 0; }
}

@keyframes blurIn {
  0% { filter: blur(10px); opacity: 0; }
  100% { filter: blur(0); opacity: 1; }
}

.blur-out {
  animation: blurOut 0s forwards;
}

.blur-in {
  animation: blurIn 0s forwards;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #333;
  color: white;
  padding: 10px 0;
  z-index: 10;
}

.footer-link {
  margin: 0 15px;
  cursor: pointer;
  text-decoration: underline;
}

.text-popup-overlay {
  position: fixed;
  top: 2%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  backdrop-filter: blur(10px);
  overflow-y: auto;
  padding: 20px; /* Added padding */
}

.text-popup {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  text-align: left;
  position: relative;
  white-space: pre-line;
  overflow-y: auto;
  z-index: 21;
}

.no-scroll {
  overflow: hidden;
}

.close-button {
  position: relative;
  left: 99%;
  background: red;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 21;
}

.explore-button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e40000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  font-size: 16px;
  text-align: center;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 20px;
  z-index: 100;
}

.logo {
  height: 50px;
  width: 40px;
  margin-left: 20px;
}

.language-selector {
  position: relative;
  display: flex;
  align-items: center;
}

.language-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.language-button .arrow-down {
  margin-left: 10px;
}

.language-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  backdrop-filter: blur(10px);
  padding: 20px; /* Added padding */
}

.language-popup {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 5px;
  width: 40%;
  height: 80%;
  text-align: left;
  position: relative;
  white-space: pre-line;
  overflow-y: auto;
}

.language-popup div {
  padding: 10px;
  cursor: pointer;
}

.language-popup div:hover {
  background-color: #eee;
}

.language-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background-color: white;
}

.language-text {
  margin-right: 5px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .image-text-wrapper {
    flex-direction: column;
    padding: 10px 0;
  }

  .image-column {
    width: 100%;
    flex-grow: 2;
    margin-left: 0;
    margin-right: 0;
  }

  .text-container {
    width: 100%;
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
    font-size: 4vw; /* Adjust font size for readability on small screens */
  }
}
